import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import '../../../style/components/article/Pagination.less';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const Pagination = props => {
    const { onPageChange, total } = props;
    const [inputPage, setInputPage] = useState('');
    const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    let totalPages = 1;
    if (total > pageSize) {
        totalPages = Math.ceil(total / pageSize);
    }
    const handlePageChange = newPage => {
        if (newPage < 1 || newPage > totalPages) return;
        setPageNumber(newPage);
        onPageChange(pageNumber, pageSize);
    };

    const handleJumpToPage = () => {
        const page = parseInt(inputPage);
        setInputPage('');
        if (isNaN(page) || page < 1 || page > totalPages) {
            return;
        }
        handlePageChange(page);
    };
    const handlePageSizeChange = e => {
        const newSize = parseInt(e.value);
        if (isNaN(newSize)) {
            return;
        }
        if (newSize === pageSize) {
            return;
        }
        // 更新每页显示的数量
        setPageSize(newSize);
        // 重置到第一页
        setPageNumber(DEFAULT_PAGE_NUMBER);
        onPageChange(pageNumber, newSize);
    };
    // page size
    const pageSizeOptions = [10, 20, 50, 100].map(size => {
        return { value: size, label: `${size}条/页` };
    });
    const selectedPageSizeOption = pageSizeOptions.find(option => option.value === pageSize);
    // page buttons
    let pageButtons = [];
    function createPageButtons(index) {
        return (
            <button
                key={index}
                className={`pagination-btn ${index === pageNumber ? 'active' : ''}`}
                onClick={() => handlePageChange(index)}
            >
                {index}
            </button>
        );
    }
    const ellipsis = (
        <div className="ellipsis" key="ellipsis">
            ...
        </div>
    );
    if (totalPages <= 10) {
        // 如果总页数小于等于10，直接显示所有页码
        for (let i = 1; i <= totalPages; i++) {
            pageButtons.push(i);
        }
    } else {
        // 如果总页数大于10，显示当前页码的前后5页
        pageButtons.push(1);
        for (let i = 2; i < totalPages; i++) {
            if (pageNumber - 5 < i && i < pageNumber + 5) {
                pageButtons.push(i);
            }
        }
        pageButtons.push(totalPages);
        for (let i = 0; i < pageButtons.length; i++) {
            const cur = pageButtons[i];
            const next = pageButtons[i + 1];
            if (next === undefined) {
                continue;
            }
            if (cur === ellipsis || next === ellipsis) {
                continue;
            }
            if (next - 1 !== cur) {
                pageButtons.splice(i + 1, 0, ellipsis);
            }
        }
    }
    pageButtons = pageButtons.map(index => {
        if (typeof index === 'number') {
            return createPageButtons(index);
        } else {
            return ellipsis;
        }
    });
    return (
        <div className="pagination">
            <div className="quick-jumper">
                <button
                    className="pre-btn"
                    disabled={pageNumber <= 1}
                    onClick={() => handlePageChange(pageNumber - 1)}
                >
                    上一页
                </button>
                {pageButtons}
                <button
                    className="next-btn"
                    disabled={pageNumber >= totalPages}
                    onClick={() => handlePageChange(pageNumber + 1)}
                >
                    下一页
                </button>
            </div>
            <div className="page-size-selector">
                <Select
                    value={selectedPageSizeOption}
                    onChange={handlePageSizeChange}
                    options={pageSizeOptions}
                    classNames={{
                        control: () => 'select-control',
                        container: () => 'select-container',
                        dropdownIndicator: () =>'select-dropdown-indicator',
                    }}
                />
            </div>
            <div className="page-number-jumper">
                <input
                    type="text"
                    value={inputPage}
                    onChange={e => setInputPage(e.target.value)}
                    placeholder={pageNumber}
                />
                <span onClick={handleJumpToPage}>跳转</span>
            </div>
        </div>
    );
};
