import { createTableDef } from "./common.mjs";

export const USER_TABLE_NAME = "user";
export const userTable = {
    tableName: USER_TABLE_NAME,
    tableDisplayName: "用户",
    columns: [
        {
            key: "avatar",
            dataType: "TEXT",
            notNull: true,
            label: "头像",
            inputType: "image",
            urlPrefix: "/assets/users/avatars/",
        },
        {
            key: "nickname",
            dataType: "TEXT",
            notNull: true,
            unique: true,
            maxLength: 15,
            minLength: 2,
            notAllowedChar: "!@#$%^&*()_+-=[]{}|;:,.<>/\\?'\"`~",
            label: "昵称",
            inputType: "text",
        },
        {
            key: "email",
            dataType: "TEXT",
            notNull: true,
            unique: true,
            label: "邮箱",
            inputType: "email",
        },
        {
            key: "password",
            dataType: "TEXT",
            notNull: true,
            label: "密码",
            inputType: "password",
        },
        {
            key: "password_salt",
            dataType: "TEXT",
            notNull: true,
            label: "密码哈希",
            inputType: "password",
            editable: false,
        },
        {
            key: "role",
            dataType: "TEXT",
            notNull: true,
            options: [
                {
                    value: "admin",
                    label: "管理员",
                },
                {
                    value: "user",
                    label: "普通用户",
                },
            ],
            label: "角色",
            inputType: "select",
        },
    ],
};

export const UserTableDef = createTableDef(userTable);
export function getUserTableDef() {
    return UserTableDef;
}
