import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import '../../../style/components/article/articleList.less';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, Pagination } from './Pagination.jsx';

export const ArticleList = ({ getArticleList = async () => {} }) => {
    const [total, setTotal] = useState(0);
    const [articles, setArticles] = useState([]);
    const handleEdit = id => {
        console.log('edit: ', id);
        window.open(`/article/upsert?id=${id}`, '_blank');
    };
    const handleDelete = id => {
        console.log('delete: ', id);
    };
    const handleClickTitle = id => {
        console.log('click title: ', id);
        window.open(`/article/view?id=${id}`, '_blank');
    };

    const handlePageChange = async (pageNumber, pageSize) => {
        const res = await getArticleList(pageNumber, pageSize);
        if (res && res.results) {
            setArticles(res.results);
            setTotal(res.total);
            return;
        }
    };
    useEffect(() => {
        handlePageChange(DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE);
    }, []);
    if (isEmpty(articles)) {
        return <div className="empty">暂无文章</div>;
    }
    return (
        <>
            <div className="article-list">
                {articles.map(article => (
                    <div className="article" key={article.id}>
                        <div className="title" onClick={() => handleClickTitle(article.id)}>
                            {article.title}
                        </div>
                        <div className="info">
                            <div className="author">作者：{article.author_name}</div>
                            <div className="created-time">
                                创建时间：{article.create_time_formatted}
                            </div>
                            <div className="updated-time">
                                更新时间：{article.update_time_formatted}
                            </div>
                            <div className="edit operation" onClick={() => handleEdit(article.id)}>
                                编辑
                            </div>
                            <div
                                className="delete operation"
                                onClick={() => handleDelete(article.id)}
                            >
                                删除
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Pagination total={total} onPageChange={handlePageChange} />
        </>
    );
};
